body {
    font-family: system-ui, sans-serif;
    padding: 8px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

em:lang(ja) {
    font-style: normal;
    color: red;
}

.explanation {
    text-decoration-line: underline;
    text-decoration-style: dotted;
    cursor: help;
}

figure {
    break-before: auto;
    break-after: auto;
    break-inside: avoid;
}

figure > img {
    object-fit: contain;
    max-width: 100%;
    height: auto;
}

footer > ol > li > cite + a {
    word-break: break-all;
}

a[rel~="external"] {
    background-image: url("./asset/external-link.svg");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 0.8em;
    padding-right: 1em;
}

@media (prefers-color-scheme: dark) {
    body {
        color: #F0F3F6;
        background: #0A0C10;
    }

    a {
        color: #71B7FF;
    }

    a:visited {
        color: #B780FF;
    }
}
