body {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  font-family: system-ui, sans-serif;
}

em:lang(ja) {
  color: red;
  font-style: normal;
}

.explanation {
  cursor: help;
  text-decoration-line: underline;
  text-decoration-style: dotted;
}

figure {
  break-before: auto;
  break-after: auto;
  break-inside: avoid;
}

figure > img {
  object-fit: contain;
  max-width: 100%;
  height: auto;
}

footer > ol > li > cite + a {
  word-break: break-all;
}

a[rel~="external"] {
  background-image: url("asset/external-link.f44e3363.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: .8em;
  padding-right: 1em;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #f0f3f6;
    background: #0a0c10;
  }

  a {
    color: #71b7ff;
  }

  a:visited {
    color: #b780ff;
  }
}
/*# sourceMappingURL=index.6a48264e.css.map */
